import * as yup from 'yup';
import { MessageConstant } from '../MessageConstant';

export const commonUserGroupValidation: { [x: string]: any; } = {
  nameList: yup.array()  
      .of(yup.string())  
      .min(1, MessageConstant.common.STRING_ARRAY_REQUIRED)
};

export const createUserGroupValidation: { [x: string]: any; } = {
  ...commonUserGroupValidation,
};

export const createUserForGroupValidationSchema = yup.object().shape(createUserGroupValidation);