import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import UserGroupFormPanel from "./Form/UserGroupFormPanel";
import PermissionMaintenance from "./PermissionMaintenance";
import UserGroupTablePanel from "./Table/UserGroupTablePanel";

const UserGroupComp:React.FC = () => {
    const [ userState ] = useUserGroupTracked();
    const { isAdd, isEditable, isSliderOpen } = userState.userGroupState;

    return <>
        <div className="main-comp-wrapper">
            {/* User table panel */}
            <UserGroupTablePanel/>
            {/* User editable panel */}
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"23%"}
                    rightSectionWidth={"77%"}
                    leftChildren={<UserGroupFormPanel/>}
                    rightChildren={<PermissionMaintenance/>}
                />
                )
            }
        </div>
    </>
}

export default memo(UserGroupComp);