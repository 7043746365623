import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import PermissionFormPanel from "./Form/PermissionFormPanel";
import UserFormPanel from "./Form/UserFormPanel";
import UserTablePanel from "./Table/UserTablePanel";

interface IContentHtml {
    leftWidth: string;
    rightWidth: string;
    content: JSX.Element;
}

const UserMaintenance = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const { isSliderOpen } = userGroupState.userState
    const { isAdd:isPermissionAdd, isEditable:isPermissionEditable } = userGroupState.permissionState;
    const { isAdd:isUserAdd, isEditable:isUserEditable } = userGroupState.userState;

    const memoTablePanel = useMemo(() => {
        return <UserTablePanel/>
    }, []);

    const memoUserFormPanel = useMemo(() => {
        return <UserFormPanel/>
    }, []);    

    const memoPermissionFormPanel = useMemo(() => {
        return <PermissionFormPanel/>
    }, [])  

    const getSliderContent = (): IContentHtml => {
        if (isPermissionAdd || isPermissionEditable) {
            return { leftWidth: '0%', rightWidth: '100%', content: memoPermissionFormPanel };
        } else if ((isUserAdd || isUserEditable) && !isPermissionAdd && !isPermissionEditable) {
            return { leftWidth: '0%', rightWidth: '100%', content: memoUserFormPanel };
        } else {
            return { leftWidth: '100%', rightWidth: '0%', content: <></> };
        }
    }

    const content: IContentHtml = getSliderContent();

    return <div className='main-comp-wrapper'>
            <SliderPanel
                isOpen={isSliderOpen}
                leftSectionWidth={content.leftWidth}
                rightSectionWidth={content.rightWidth}
                draggable={true}
                leftChildren={memoTablePanel}
                rightChildren={content.content} />
        </div>
}

export default memo(UserMaintenance);