export const userGroupRequiredFieldList: string[] = [
    'name',
];

export const UserGroupConstant = {
    userGroup: {
        USERGROUP: "User Role",
        USERGROUPNAME: "Name",
        USERGROUPDESC: "Description",
        USERGROUPSETTING: "Administrator",
        ADDGROUP: "Add User Role",
        UPDATEGROUP: "Update User Role",
    }
}