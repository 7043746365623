import * as yup from 'yup';
import { MessageConstant, formatMessage } from '../MessageConstant';
import { UserGroupConstant } from './UserGroupConstant';

export const commonAssginGroupValidation: { [x: string]: any; } = {
    nameList: yup.array()  
    .of(yup.string())  
    .min(1, formatMessage(MessageConstant.common.FIELD_REQUIRED, {"{fieldName}":UserGroupConstant.userGroup.USERGROUPNAME}))
};

export const commonUserGroupValidation: { [x: string]: any; } = {
    name: yup.string().required()
};

export const createUserGroupValidation: { [x: string]: any; } = {
  ...commonUserGroupValidation,
};

export const assignGroupValidation: { [x: string]: any; } = {
  ...commonUserGroupValidation,
};

export const createUserGroupValidationSchema = yup.object().shape(createUserGroupValidation);
export const assignGroupValidationSchema = yup.object().shape(assignGroupValidation);