import { EMPTY_CHANGE_PASSWORD_MODEL } from "presentation/model/ChangePassword/ChangePasswordModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: ChangePasswordProvider,
    useTracked: useChangePasswoTracked
} = createContainer(() => useState(EMPTY_CHANGE_PASSWORD_MODEL), {concurrentMode: true});
export { ChangePasswordProvider, useChangePasswoTracked };

