import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";

const USERGROUP_CONSTANT = UserGroupConstant.userGroup;

export const INITIAL_USER_GROUP_COL_DEF: any[] = [
    {
        headerName: USERGROUP_CONSTANT.USERGROUPNAME,
        field: 'name',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: USERGROUP_CONSTANT.USERGROUPDESC,
        field: 'description',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },    
].map((col, index) => ({ ...col, header: col.headerName, id: index + 1, dataType: 'text' }));