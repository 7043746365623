import { EMPTY_USER_GROUP_ENTITY, UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { DropdownProps } from "../DropdownProps";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { UserGroupViewChangeState, UserViewChangeState } from "../User/UserModel";
import { EMPTY_PERMISSION_ENTITY, PermissionEntity } from "./PermissionEntity";
import { EMPTY_USER_ENTITY } from "domain/entity/User/UserEntity";

export interface UserGroupDropdownOptions {
    userDropdownOptions: DropdownProps[] | null,
    permissionDropdownOptions: DropdownProps[] | null,
    operatingCompanyDropdownOptions: DropdownProps[] | null
}

export interface PermissionViewChangeState extends BaseViewChangeSate {
    currentSelectedData: PermissionEntity
}

export interface UserGroupModel {
    userGroups: UserGroupEntity[],
    dynamicOptions: UserGroupDropdownOptions,
    isLoading: boolean,
    userState: UserViewChangeState,
    userGroupState: UserGroupViewChangeState,
    permissionState: PermissionViewChangeState
}

export const EMPTY_USER_GROUP_MODEL:UserGroupModel = {
    userGroups: [],
    dynamicOptions: {
        permissionDropdownOptions: [],
        userDropdownOptions: [],
        operatingCompanyDropdownOptions: []
    },
    isLoading: false,
    userState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: false, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_USER_ENTITY,
        lastEditRowId: "",
        isSelected: true,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    },
    userGroupState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_USER_GROUP_ENTITY,
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    },
    permissionState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: false, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_PERMISSION_ENTITY,
        lastEditRowId: "",
        isSelected: true,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    }
}