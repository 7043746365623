import * as yup from 'yup';

export const commonChangePasswordValidation: { [x: string]: any; } = {
  password: yup.string().required("Missing"),
  newPassword: yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-.:/?\\|<>~`{}[\]";',]).+$/,
      `Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character[! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _  { | } ~\`]`
    )
    .min(8, 'Password must be at least 8 characters')
    .required('Missing')
    .not([yup.ref('password')], 'New password cannot be the same as the current password'),
  retypePassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'New password and retype password must match')
    .required('Missing'),
};

export const changePasswordValidation: { [x: string]: any; } = {
  ...commonChangePasswordValidation,
};

export const changePasswordValidationSchema = yup.object().shape(changePasswordValidation);