import { useMemo } from "react";
import { UserGroupRepoImpl } from "domain/repository/UserGroup/UserGroupRepoImpl";
import { UserRepoImpl } from "domain/repository/User/UserRepoImpl";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { UserGroupVM } from "presentation/viewModel/UserGroup/UserGroupVM";
import { MenuRepoImpl } from "domain/repository/Menu/MenuRepoImpl";
import { BuProfileRepoImpl } from "domain/repository/BuProfile/BuProfileRepoImpl";

export const useUserGroupVM = () => {
    const [, setUserGroupState] = useUserGroupTracked();
    const userGroupVM = useMemo(() =>
        UserGroupVM({
            dispatch: [setUserGroupState],
            userGroupRepo: UserGroupRepoImpl(),
            userRepo: UserRepoImpl(),
            menuRepo: MenuRepoImpl(),
            buProfileRepo: BuProfileRepoImpl(),
        }), [setUserGroupState])

    return userGroupVM;
}