import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { useMemo } from "react";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import UserGroupTitleBar from "../UserGroupTitleBar";
import UserGroupForm from "./UserGroupForm";

const UserGroupFormPanel : React.FC = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const { isAdd, isEditable, isRead } = userGroupState.userGroupState;
    const currentGroup = userGroupState.userGroupState.currentSelectedData;
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;

    const memoUserGroupTitleBar = useMemo(() => {
        return <UserGroupTitleBar/>
    }, [])

    const memoUserGroupForm = useMemo(() => {
        return <UserGroupForm/>
    }, [])

    return <StyledSidebar style={{ width: '100%', border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoUserGroupTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: '100%', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                {/* If user add new record */}
                                {isAdd && <SidebarTitle>{USERGROUP_CONSTANT.ADDGROUP}</SidebarTitle>}

                                {/* If user edit existing record */}
                                {isEditable && <SidebarTitle>{currentGroup.name}</SidebarTitle>}

                                {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>

                        {/* General Information */}
                        {memoUserGroupForm}
                    </div>
                </div>
            </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}

export default UserGroupFormPanel;