import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import PermissionTablePanel from "./Table/PermissionTablePanel";
import UserMaintenance from "./UserMaintenance";

const PermissionMaintenance:React.FC = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const { isSliderOpen } = userGroupState.userGroupState;

    const memoTablePanel = useMemo(() => {
        return <PermissionTablePanel/>
    }, []);

    const memoMaintenance = useMemo(() => {
        return <UserMaintenance/>
    }, []);

    return <div className='main-comp-wrapper'>
            <SliderPanel
                isOpen={isSliderOpen}
                leftSectionWidth={"65%"}
                rightSectionWidth={"35%"}
                draggable={false}
                leftChildren={memoTablePanel}
                rightChildren={memoMaintenance} />
        </div>
}

export default memo(PermissionMaintenance);