import { UserGroupEntity } from "../userGroup/UserGroupEntity"

export interface UserEntity {
    name: string,
	password: string,
    newPassword: string,
    retypePassword?: string,
	emailAddress: string,
	description?: string | null,
	systemAccessMode?: string | null,
    passwordEffectiveSinceDate: Date | null,
    passwordEffectivePeriodDay: number | null,
    passwordResetByAdminDate?: Date | null,
    passwordResetByUserDate?: Date | null,
    lastLoginDate: Date | null,
	firstName?: string | null,
    lastName?: string | null,
    validityFromDate: Date | null,
    validityToDate: Date | null,
    lastUpdatedBy?: string | null,
    lastUpdateDescription?: string | null,
    lastUpdateDatetime: Date | null,
    groups?: UserGroupEntity[] | null,
    defaultEntryPoint: string | null,
    defaultOperatingCompany: string | null,
    switchOperatingCompany: string | null,
	version: number | null,
    nameList?: string[]
}

export const EMPTY_USER_ENTITY:UserEntity = {
    name: "",
	password: "",
    newPassword: "",
    retypePassword: "",
	emailAddress: "",
	description: null,
	systemAccessMode: null,
    passwordEffectiveSinceDate: null,
    passwordEffectivePeriodDay: 60,
    passwordResetByAdminDate: null,
    passwordResetByUserDate: null,
    lastLoginDate: null,
	firstName: null,
    lastName: null,
    validityFromDate: null,
    validityToDate: null,
    lastUpdatedBy: null,
    lastUpdateDescription: null,
    lastUpdateDatetime: null,
    groups: [],
    defaultEntryPoint: null,
    defaultOperatingCompany: "2691",
    switchOperatingCompany: "2691",
	version: 0,
    nameList: []
}