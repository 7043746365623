export const permissionRequiredFieldList: string[] = [
    'resource',
    'attribute'
];

export const PermissionConstant = {
    permission: {
        RESOURCENAME: "Resource Name",
        ATTRIBUTES: "Attribute",
        ALLOWEDREAD: "Read",
        ALLOWEDCREATE: "Create",
        ALLOWEDDELETE: "Delete",
        ALLOWEDUPDATE: "Modify",
        ADDPERMISSION: "Add Permission",
        UPDATEPERMISSION: "Update Permission",
        PERMISSION: "Permission"
    }
}