export interface PermissionEntity {
    resource: string,
    read: boolean,
    modify: boolean,
    create: boolean,
    delete: boolean,
    attributes: string[],
    tempResource?: string[]
}

export const EMPTY_PERMISSION_ENTITY:PermissionEntity = {
    resource: "",
    read: false,
    modify: false,
    create: false,
    delete: false,
    attributes: [],
    tempResource: []
}