import { IMenuEntity } from "domain/entity/Navigation";
// import workspaceAxiosInstance from "../axios/workspaceAxiosInstance";
import { MenuRepository } from "./MenuRepo";
import axios from "axios";

export const MenuRepoImpl = (): MenuRepository => {
    // const endPoint = '/v1/workspacemenu'
    // const getMenuByKey = async (menuId: string): Promise<IMenuEntity> => {
        // const result = workspaceAxiosInstance.get(`${endPoint}/${menuId}`, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // })
        //     .then(res => {
        //         return res.data
        //     }).catch(error => {
        //         return null
        //     })
    //     return result
    // }
    const getMenuByKey = async (menuId: string): Promise<IMenuEntity | null> => {
        const result = axios.get('/nbisiv_main_menu.json')
            .then(res => {
                const workspaceMenu = res.data;
                if (workspaceMenu) {
                    const menuList = workspaceMenu.workspaceMenuDTOList as IMenuEntity[];
                    return menuList.find(menu => menu.menuId === menuId)??null;
                }
                return null;
            })
            .catch(() => {
                return null;
            });
        return result;
    }

    return {
        getMenuByKey: getMenuByKey,
    }
}