import { UserConStant } from "presentation/constant/User/UserConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarCaption, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import UserForm from "./UserForm";

const UserFormPanel : React.FC = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const currentUser = userGroupState.userState.currentSelectedData;
    const { isAdd, isEditable, isRead } = userGroupState.userState;
    const userGroupVM = useUserGroupVM();
    const USER_CONSTANT = UserConStant.user;
    const [, setIsSave] = useState(false);

    const closeSlider = useCallback(() => {
        userGroupVM.onUserCloseClick();
    }, [userGroupVM])

    const saveButtonClicked = useCallback(async() => {
        setIsSave(true);        
        userGroupVM.onUserSaveClicked();
        await userGroupVM.onUserSave(currentUser, isAdd);
    }, [userGroupVM, isAdd, currentUser]);

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            {/* If user add new record */}
                            {isAdd && <SidebarTitle>{USER_CONSTANT.ADDUSER}</SidebarTitle>}

                            {/* If user edit existing record */}
                            {isEditable && <SidebarTitle>{currentUser.name}</SidebarTitle>}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>{"All fields are required unless indicated as 'optional'"}</SidebarCaption>}
                        </SidebarTitle>
                        <SidebarActionCross>
                            {isRead && <IconButton
                             fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeSlider} />}
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>
            {/* Stack Form Start */}
            <UserForm/>
            <SidebarActionBar>
                {!isRead && <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={closeSlider} />}
                {!isRead && <HPHButton label={isAdd ? 'Add' : 'Update'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />}
            </SidebarActionBar>
        </div>
    );
}

export default memo(UserFormPanel);