import { EMPTY_USER_ENTITY, UserEntity } from "domain/entity/User/UserEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { UserRepository } from "./UserRepo";

export const UserRepoImpl = (): UserRepository => {
    const url = '/v1/user';

    const getAllUsers = async() : Promise<UserEntity[]> => {
        return await axiosGetData(authAxiosInstance, `${url}`, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getUserByName = async(name:string): Promise<UserEntity> => {
        return await axiosGetData(authAxiosInstance, `${url}/${name}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createNewUser = async(newData: UserEntity) : Promise<UserEntity> => {
        return await axiosPostData(authAxiosInstance, `${url}`, newData).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Create user Failure"
                if (res.data && typeof (res.data) === "string") {
                    if (res.data.includes("Already Exist")) {
                        errorMessage = `User name "${newData.name}" already exists.`
                    } else {
                        errorMessage = res.data;
                    }
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {            
            return "Error:" + err.message;
        });
    }

    const updateUser = async(updatedData: UserEntity): Promise<UserEntity> => {
        return await axiosPutData(authAxiosInstance, `${url}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Update user Failure"
                if (res.data && typeof (res.data) === "string") {
                    if (res.data.includes("Already Exist")) {
                        errorMessage = `User name "${updatedData.name}" already exists.`
                    } else {
                        errorMessage = res.data;
                    }
                }
                return "Error:" + errorMessage;  
            }
        }).catch(error => {
            return "Error:" + error.message;
        })
    }

    const deleteUserByName = async(name: string): Promise<boolean> => {
        return await axiosDeleteData(authAxiosInstance, `${url}`, name).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const updateLatestLoginDatetime = async(name:string): Promise<boolean> => {
        const user:UserEntity = EMPTY_USER_ENTITY;
        user.name = name;
        const tempUrl = `/v1/updateLatestLoginDatetime`;
        return await axiosPutData(authAxiosInstance, `${tempUrl}`, user).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const setDefaultOperatingCompany = async(name:string, operatingCompany:string): Promise<boolean> => {
        const user:UserEntity = EMPTY_USER_ENTITY;
        user.name = name;
        user.defaultOperatingCompany = operatingCompany;
        const tempUrl = `/v1/setDefaultOperatingCompany`;
        return await axiosPutData(authAxiosInstance, `${tempUrl}`, user).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const switchOperatingCompany = async(name:string, switchOperatingCompany:string): Promise<boolean> => {
        const user:UserEntity = EMPTY_USER_ENTITY;
        user.name = name;
        user.switchOperatingCompany = switchOperatingCompany;
        const tempUrl = `/v1/switchOperatingCompany`;
        return await axiosPutData(authAxiosInstance, `${tempUrl}`, user).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const changePassword = async(updatedData: UserEntity): Promise<Object> => {
        const tempUrl = `/v1/changePassword`;
        return await axiosPutData(authAxiosInstance, `${tempUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Change Password Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch((err) => {
            return "Error:" + err.Message; 
        })
    }

    return {
        getAllUsers: getAllUsers,
        getUserByName: getUserByName,
        createNewUser: createNewUser,
        updateUser: updateUser,
        deleteUserByName: deleteUserByName,
        updateLatestLoginDatetime: updateLatestLoginDatetime,
        setDefaultOperatingCompany: setDefaultOperatingCompany,
        switchOperatingCompany: switchOperatingCompany,
        changePassword: changePassword
    }
}