import { EMPTY_USER_ENTITY, UserEntity } from "domain/entity/User/UserEntity";
import { DropdownProps } from "../DropdownProps";
import { EMPTY_USER_GROUP_ENTITY, UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";

export interface UserDropdownOptions {
    userGroupDropdownOptions: DropdownProps[] | null,
    menuDropdownOptions: DropdownProps[] | null
}

export interface UserViewChangeState extends BaseViewChangeSate {
    currentSelectedData: UserEntity
}

export interface UserGroupViewChangeState extends BaseViewChangeSate {
    currentSelectedData: UserGroupEntity
}

export interface UserModel {
    users: UserEntity[],    
    dynamicOptions: UserDropdownOptions,
    showGroupDescriptionField: boolean,
    isLoading: boolean,
    userState: UserViewChangeState,
    userGroupState: UserGroupViewChangeState, 
}

export const EMPTY_USER_MODEL:UserModel = {
    users: [],
    dynamicOptions: {
        userGroupDropdownOptions:[],
        menuDropdownOptions:[]
    },
    isLoading: false,
    showGroupDescriptionField: false,
    userState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_USER_ENTITY,
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    },    
    userGroupState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_USER_GROUP_ENTITY,
        lastEditRowId: "",
        isSelected: true,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    }
}