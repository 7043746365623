import { MessageConstant, formatMessage } from "presentation/constant/MessageConstant";
import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const UserGroupTitleBar:React.FC = () => {    
    const [ userGroupState ] = useUserGroupTracked();
    const [ anainfoState ] = useANAInfoTracked();
    const userGroupVM = useUserGroupVM();
    const messageBarVM = useMessageBarVM();
    const { isAdd, isEditable, isRead, currentSelectedData, allFormState } = userGroupState.userGroupState;
    const {isAdd:isUserAdd, isRead:isUserRead, isEditable:isUserEditable} = userGroupState.userState;
    const {isAdd:isPermissionAdd, isRead:isPermissionRead, isEditable:isPermissionEditable} = userGroupState.permissionState;
    const { allowCreate, allowUpdate } = anainfoState;
    const [, setIsSave] = useState(false);
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;

    const isDisable = () => {
        if (isUserAdd || (!isUserRead && isUserEditable)) return true;
        if (isPermissionAdd || (!isPermissionRead && isPermissionEditable)) return true;

        return false;
    }

    const handleClose = useCallback(() => {
        userGroupVM.onCloseClick();
    }, [userGroupVM]);

    const handleEdit = useCallback(() => {
        userGroupVM.onEditClick();
    }, [userGroupVM]);

    const handleReset = useCallback(() => {
        userGroupVM.onResetClick();
    }, [userGroupVM]);

    const handleSave = useCallback(async(isAdd:boolean) => {
        setIsSave(true);
        userGroupVM.onShowLoading();
        userGroupVM.onUserGroupSaveClicked();
        userGroupVM.onUserGroupSave(currentSelectedData, isAdd).then((res) => {
            if (res && res["SaveUserGroupFail"]) {
                messageBarVM.showError(res["SaveUserGroupFail"]?.toString());
            } else if (!res) {
                let msg = MessageConstant.common.SAVED_DATA_SUCCESSFUL;
                if (!isAdd) {
                    msg = MessageConstant.common.UPDATED_DATA_SUCCESSFUL
                }
                messageBarVM.showSuccess(formatMessage(msg,
                    {"{recordName}":currentSelectedData.name}));
                userGroupVM.loadAllUserGroups();
            }
        }).catch((error) => {
            userGroupVM.onHideLoading();
        }).finally(() => {            
            userGroupVM.onHideLoading();
        })
    }, [userGroupVM, messageBarVM, currentSelectedData]);

    return <Sidebarheader style={{width: '100%'}}>
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: USERGROUP_CONSTANT.USERGROUPSETTING }, { title: USERGROUP_CONSTANT.USERGROUP }]} onCurrentClick={handleClose}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={handleClose} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave(isAdd)} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(UserGroupTitleBar);