import { BuProfileEntity } from "domain/entity/BuProfile/BuProfileEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData, axiosUploadFile } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { BuProfileRepository } from "./BuProfileRepo";

export const BuProfileRepoImpl = (): BuProfileRepository => {
    const url = '/v1/buprofile';

    const getAllBuProfiles = async() : Promise<BuProfileEntity[]> => {
        return await axiosGetData(authAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }

    const getBuProfileByKey = async(key: string): Promise<BuProfileEntity> => {
        return await axiosGetData(authAxiosInstance, `${url}/bu/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewBuProfile = async(newData: BuProfileEntity) : Promise<BuProfileEntity> => {
        const result = await axiosPostData(authAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Charge Type Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Charge Type ${newData.chargeType} Sub Charge Type ${newData.subChargeType}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateBuProfile = async(updatedData: BuProfileEntity): Promise<BuProfileEntity> => {
        const result = await axiosPutData(authAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteBuProfileByKey = async(key: string): Promise<boolean> => {
        return await axiosDeleteData(authAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const uploadBuProfileImages = async(file: any): Promise<string> => {
        return await axiosUploadFile(authAxiosInstance, `${url}/upload`, file).then(res => {
            return res.data
        }).catch(error => {
            return null
        })
    }

    const loadBuProfileImages = async(fileName: String): Promise<any> => {
        return await axiosGetData(authAxiosInstance, `${url}/loadImage/${fileName}`).then(res => {
            return res.data
        }).catch(error => {
            return null
        })
    }

    return {
        getAllBuProfiles: getAllBuProfiles,
        getBuProfileByKey: getBuProfileByKey,
        createNewBuProfile: createNewBuProfile,
        updateBuProfile: updateBuProfile,
        deleteBuProfileByKey: deleteBuProfileByKey,
        uploadBuProfileImages:uploadBuProfileImages,
        loadBuProfileImages:loadBuProfileImages
    }
}