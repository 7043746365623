import * as yup from 'yup';
import { MessageConstant } from '../MessageConstant';

export const commonPermissionGroupValidation: { [x: string]: any; } = {
  tempResource: yup.array()  
      .of(yup.string())  
      .min(1, MessageConstant.common.STRING_ARRAY_REQUIRED),
  attributes: yup.array()  
      .of(yup.string())  
      .min(1, MessageConstant.common.STRING_ARRAY_REQUIRED)
};

export const createPermissionGroupValidation: { [x: string]: any; } = {
  ...commonPermissionGroupValidation,
};

export const createPermissionValidationSchema = yup.object().shape(createPermissionGroupValidation);