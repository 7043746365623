import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { UserGroupProvider } from "presentation/store/UserGroup/UserGroupProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import UserGroupComp from "presentation/view/section/UserGroup/Group/UserGroupComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const UserGroupContMain = () => {
    return <ANAInfoWrapper permission={Permission.USERGROUP}>
        <MessageBarWrapper>
            <UserGroupProvider>
                <GridStyles/>
                <UserGroupComp/>
            </UserGroupProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default UserGroupContMain;