import { PermissionConstant } from "presentation/constant/UserGroup/PermissionConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarCaption, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import PermissionForm from "./PermissionForm";

const PermissionFormPanel : React.FC = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const currentPermission = userGroupState.permissionState.currentSelectedData;
    const { isAdd, isEditable, isRead } = userGroupState.permissionState;
    const userGroupVM = useUserGroupVM();
    const Permission_CONSTANT = PermissionConstant.permission;
    const [, setIsSave] = useState(false);

    const closeSlider = useCallback(() => {
        userGroupVM.onPermissiCloseClick();
    }, [userGroupVM])

    const saveButtonClicked = useCallback(async() => {
        setIsSave(true);        
        userGroupVM.onPermissionSaveClicked();
        await userGroupVM.onPermissionSave(currentPermission, isAdd);
    }, [userGroupVM, isAdd, currentPermission]);

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            {/* If user add new record */}
                            {isAdd && <SidebarTitle>{Permission_CONSTANT.ADDPERMISSION}</SidebarTitle>}

                            {/* If user edit existing record */}
                            {isEditable && <SidebarTitle>{currentPermission.resource}</SidebarTitle>}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>{"All fields are required unless indicated as 'optional'"}</SidebarCaption>}
                        </SidebarTitle>
                        <SidebarActionCross>
                            {isRead && <IconButton
                             fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeSlider} />}
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>
            {/* Stack Form Start */}
            <PermissionForm/>
            <SidebarActionBar>
                {!isRead && <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={closeSlider} />}
                {!isRead && <HPHButton label={isAdd ? 'Add' : 'Update'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />}
            </SidebarActionBar>
        </div>
    );
}

export default memo(PermissionFormPanel);