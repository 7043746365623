import { UserConStant } from "presentation/constant/User/UserConstant";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";

const USER_CONSTANT = UserConStant.user;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];

export const INITIAL_USER_COL_DEF: any[] = [
    {
        headerName: USER_CONSTANT.USERNAME,
        field: 'name',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        pinned: "left",
        width: 150,
    },
    {
        headerName: USER_CONSTANT.USERFIRSTNAME,
        field: 'firstName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: USER_CONSTANT.USERLASTNAME,
        field: 'lastName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: USER_CONSTANT.EMAIL,
        field: 'emailAddress',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: USER_CONSTANT.PREFERENCEENTRYPOINT,
        field: 'defaultEntryPoint',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: USER_CONSTANT.DESCRIPTION,
        field: 'description',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },  
    {
        headerName: USER_CONSTANT.VALIDITYDATEFROM,
        field: 'validityFromDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType:"date",
    },   
    {
        headerName: USER_CONSTANT.VALIDITYDATETO,
        field: 'validityToDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType:"date",
    },  
    {
        headerName: USER_CONSTANT.LASTLOGINDATE,
        field: 'lastLoginDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType:"dateTime",
    }, 
    {
        headerName: USER_CONSTANT.LASTUPDATE,
        field: 'lastUpdateDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType:"dateTime",
    }, 
    {
        headerName: USER_CONSTANT.LASTUPDATEDBY,
        field: 'lastUpdatedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    }, 
    {
        headerName: USER_CONSTANT.LASTUPDATEDDESC,
        field: 'lastUpdateDescription',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    }, 
].map((col, index) => { 
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], {});
});

export const INITIAL_USER_COL_FOR_GROUP_DEF: any[] = [
    {
        headerName: USER_CONSTANT.USERNAME,
        field: 'name',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
    },
    {
        headerName: USER_CONSTANT.DESCRIPTION,
        field: 'description',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },  
].map((col, index) => { 
    return {
        ...col,
        header: col.headerName, id: index + 1, dataType: 'text'
    }
});

export const transferRowData = (data:any[]) => {
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], {});
}