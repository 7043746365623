import { UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { UserGroupRepository } from "./UserGroupRepo";

export const UserGroupRepoImpl = (): UserGroupRepository => {
    const url = '/v1/userGroup';

    const getAllUserGroups = async() : Promise<UserGroupEntity[]> => {
        return await axiosGetData(authAxiosInstance, `${url}`, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getUserGroupByName = async(name: string): Promise<UserGroupEntity> => {
        return await axiosGetData(authAxiosInstance, `${url}/${name}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createNewUserGroup = async(newData: UserGroupEntity) : Promise<UserGroupEntity> => {
        return await axiosPostData(authAxiosInstance, `${url}`, newData).then(res => {
            if (res.success) {
                return res.data;
            } else {
                let errorMessage = "Create user role Failure"
                if (res.data && typeof (res.data) === "string") {
                    if (res.data.includes("Already Exist")) {
                        errorMessage = `User role name "${newData.name}" already exists.`
                    }
                }
                throw new Error(errorMessage); 
            }
        }).catch(err => {
            throw new Error(err.message);
        });
    }

    const updateUserGroup = async(updatedData: UserGroupEntity): Promise<UserGroupEntity> => {
        return await axiosPutData(authAxiosInstance, `${url}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Update user role Failure"
                if (res.data && typeof (res.data) === "string") {
                    if (res.data.includes("Already Exist")) {
                        errorMessage = `User role name "${updatedData.name}" already exists.`
                    }
                }
                throw new Error(errorMessage); 
            }
        }).catch(error => {
            return null
        })
    }

    const deleteUserGroupByName = async(name: string): Promise<boolean> => {
        return await axiosDeleteData(authAxiosInstance, `${url}`, name).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllUserGroups: getAllUserGroups,
        geUserGroupByName: getUserGroupByName,
        createNewUserGroup: createNewUserGroup,
        updateUserGroup: updateUserGroup,
        deleteUserGroup: deleteUserGroupByName
    }
}