import { PermissionConstant, permissionRequiredFieldList } from "presentation/constant/UserGroup/PermissionConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { memo } from "react";
import { CommonField, FieldType, HPHCheckbox } from "veronica-ui-component/dist/component/core";

const PermissionForm = () => {
    const [ userGroupState ] = useUserGroupTracked();
    const userGroupVM = useUserGroupVM();
    const {isRead, isSaveClicked} = userGroupState.permissionState;
    const currentPermission = userGroupState.permissionState.currentSelectedData;
    const PERMISSION_CONSTANT = PermissionConstant.permission;
    const allFormState = userGroupState.permissionState.allFormState;

    return (<>
        <div className={'im-flex-row'}>
            <div className={'im-flex-row-item'} style={{width:'390px', marginBottom:'10px'}}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    readOnlyValue={currentPermission?.tempResource?.join(",") ?? ''}
                    fieldValue={currentPermission?.resource}
                    fieldLabel={PERMISSION_CONSTANT.RESOURCENAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'tempResource'}
                    isMultipleDropDown={true}
                    requiredFieldList={permissionRequiredFieldList}
                    isShowOptional={false}
                    options={userGroupState.dynamicOptions.permissionDropdownOptions??[]}
                    onFieldChange={userGroupVM.onPermissionFieldChange} />
            </div>
        </div>
        <div className={'im-flex-row'}>
            <div className={'im-flex-row-item'} style={{ width: '80px', alignItems: 'flex-start', marginLeft: `${isRead ? '0' : '-5px'}` }}>
                {/*<CommonField
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentPermission.create === true ? 'Yes' : 'No'}
                    fieldValue={currentPermission.create || false}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.CHECKBOX_GROUP}
                    fieldKey={'create'}
                    requiredFieldList={permissionRequiredFieldList}                    
                    options={[
                        {key:PERMISSION_CONSTANT.ALLOWEDCREATE, name:PERMISSION_CONSTANT.ALLOWEDCREATE},
                        {key:PERMISSION_CONSTANT.ALLOWEDUPDATE, name:PERMISSION_CONSTANT.ALLOWEDUPDATE},
                        {key:PERMISSION_CONSTANT.ALLOWEDREAD, name:PERMISSION_CONSTANT.ALLOWEDREAD},
                        {key:PERMISSION_CONSTANT.ALLOWEDDELETE, name:PERMISSION_CONSTANT.ALLOWEDDELETE}
                    ]}
                    onFieldChange={userGroupVM.onPermissionFieldChange}
                />*/}
                <HPHCheckbox 
                    id={"createCheckbox"}
                    label={PERMISSION_CONSTANT.ALLOWEDCREATE}
                    checked={userGroupState.permissionState.currentSelectedData.create}
                    onChange={(e) => userGroupVM.onPermissionFieldChange("create", e.checked)}
                />
            </div>
            <div className={'im-flex-row-item'} style={{ width: '80px', alignItems: 'flex-start', marginLeft: `${isRead ? '0' : '-5px'}` }}>
                {/*<CommonField
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentPermission.modify === true ? 'Yes' : 'No'}
                    fieldValue={currentPermission.modify || false}
                    fieldLabel={PERMISSION_CONSTANT.ALLOWEDUPDATE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TOGGLE}
                    fieldKey={'modify'}
                    requiredFieldList={permissionRequiredFieldList}
                    onFieldChange={userGroupVM.onPermissionFieldChange}
                />*/}
                <HPHCheckbox 
                    id={"modifyCheckbox"}
                    label={PERMISSION_CONSTANT.ALLOWEDUPDATE}
                    checked={userGroupState.permissionState.currentSelectedData.modify}
                    onChange={(e) => userGroupVM.onPermissionFieldChange("modify", e.checked)}
                />
            </div>
            <div className={'im-flex-row-item'} style={{ width: '80px', alignItems: 'flex-start', marginLeft: `${isRead ? '0' : '-5px'}` }}>
                {/*<CommonField
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentPermission.read === true ? 'Yes' : 'No'}
                    fieldValue={currentPermission.read || false}
                    fieldLabel={PERMISSION_CONSTANT.ALLOWEDREAD}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TOGGLE}
                    fieldKey={'read'}
                    requiredFieldList={permissionRequiredFieldList}
                    onFieldChange={userGroupVM.onPermissionFieldChange}
                />*/}
                <HPHCheckbox 
                    id={"modifyCheckbox"}
                    label={PERMISSION_CONSTANT.ALLOWEDREAD}
                    checked={userGroupState.permissionState.currentSelectedData.read}
                    onChange={(e) => userGroupVM.onPermissionFieldChange("read", e.checked)}
                />
            </div>
            <div className={'im-flex-row-item'} style={{ width: '80px', alignItems: 'flex-start', marginLeft: `${isRead ? '0' : '-5px'}` }}>
                {/*<CommonField
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentPermission.delete === true ? 'Yes' : 'No'}
                    fieldValue={currentPermission.delete || false}
                    fieldLabel={PERMISSION_CONSTANT.ALLOWEDDELETE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TOGGLE}
                    fieldKey={'delete'}
                    requiredFieldList={permissionRequiredFieldList}
                    onFieldChange={userGroupVM.onPermissionFieldChange}
                />*/}
                <HPHCheckbox 
                    id={"modifyCheckbox"}
                    label={PERMISSION_CONSTANT.ALLOWEDDELETE}
                    checked={userGroupState.permissionState.currentSelectedData.delete}
                    onChange={(e) => userGroupVM.onPermissionFieldChange("delete", e.checked)}
                />
            </div>
        </div>
        <div className={'im-flex-row'}>
            <div className={'im-flex-row-item'}  style={{width:'390px'}}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    readOnlyValue={currentPermission?.attributes?.join(",") || ''}
                    fieldValue={currentPermission?.attributes?.toString()}
                    fieldLabel={PERMISSION_CONSTANT.ATTRIBUTES}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'attributes'}
                    isMultipleDropDown={true}
                    isShowOptional={false}
                    requiredFieldList={permissionRequiredFieldList}
                    options={userGroupState.dynamicOptions.operatingCompanyDropdownOptions??[]}
                    onFieldChange={userGroupVM.onPermissionFieldChange} />
            </div>
        </div>
    </>);
}

export default memo(PermissionForm);