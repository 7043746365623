import { PermissionEntity } from "presentation/model/UserGroup/PermissionEntity"
import { UserEntity } from "../User/UserEntity"

export interface UserGroupEntity {
    name: string,
	description?: string | null,
	lastUpdatedBy?: string | null,
	lastUpdateDate: Date | null,
	version: number | null,
	users?: UserEntity[] | null,
    permissions?: PermissionEntity[] | null,
	nameList?: string[]
	
    [key:string]: string | number | Date | null | undefined | {} | []
}

export const EMPTY_USER_GROUP_ENTITY:UserGroupEntity = {
    name: "",
	description: null,
	lastUpdatedBy: null,
	lastUpdateDate: null,
	version: null,
	users: [],
    permissions: [],
	nameList: []
}