import { EMPTY_USER_ENTITY, UserEntity } from "domain/entity/User/UserEntity";

export interface ChangePasswordModel {
    user: UserEntity | null,
    allFormState: {[key:string]:string}
}

export const EMPTY_CHANGE_PASSWORD_MODEL:ChangePasswordModel = {
    user: EMPTY_USER_ENTITY,
    allFormState: {}
}